import React, { FunctionComponent } from 'react';
import { Center, Grid, Group, Image, Space, Text, Title } from "@mantine/core";
import AboutUsMain from "../resources/aboutUs_main.jpg";
import AboutUs1 from "../resources/aboutUs_1.jpg";
import AboutUs2 from "../resources/aboutUs_2.jpg";
import AboutUs3 from "../resources/aboutUs_3.jpg";
import Footer from "../Footer";
import { useMediaQuery } from "@mantine/hooks";

const AboutUs: FunctionComponent<any> = (props) => {

    const isSmallScreen = useMediaQuery('(max-width: 500px)');

    return (
        <>
            <div
                style={{
                    position: "relative",
                    width: "100%",
                    height: "100%",
                    background: "linear-gradient( rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25) ), url(" + AboutUsMain + ")",
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat'
                }}
            >
                <Center style={{height: "100%"}}>
                    <Title style={{color: "white", fontSize: isSmallScreen ? "54px" : "100px", fontWeight: 700, textAlign: "center"}}>WE ARE FLY E-FOIL SCHOOL</Title>
                </Center>
            </div>
            <Grid>
                <Grid.Col span={isSmallScreen ? 12 : 6} p={isSmallScreen ? 50 : 80} pt={40} pb={isSmallScreen ? 0 : "unset"} style={{backgroundColor: "#C2C2C2"}}>
                    <Title
                        style={{
                            fontStyle: "normal",
                            fontWeight: 700,
                            fontSize: isSmallScreen ? 30 : 80,
                            lineHeight: isSmallScreen ? "40px" : "93px"
                        }}
                    >
                        COME & SHARE YOUR EXPERIENCE WITH US
                    </Title>
                </Grid.Col>
                <Grid.Col span={isSmallScreen ? 12 : 6} pt={isSmallScreen ? 20 : 50} p={isSmallScreen ? 50 : 80} style={{backgroundColor: "#C4C4C4"}}>
                    <Text style={{
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: isSmallScreen ? 22 : 28,
                        lineHeight: "33px"
                    }}>
                        <p>
                            Partnered with Lift Foils, FLY E-Foil offers guests an unparalleled watersport experience as you fly over the water.
                            Whether you’re traveling with a group, looking for a family-friendly activity or a solo traveler looking for a new adventure FLY E-Foil has you covered! We make our E-Foil lessons from Ulcinj until Croatian border.
                        </p>
                        <br/>
                        <p>
                            Our goal is to help accelerate and spread the shift towards more environmentally friendly powered marine craft. We work directly and represent the best brands on the market to bring you the best experience possible.
                        </p>
                        <br/>
                        <p>Our main location is at Dolcinium Kite School at Velika Plaza, Ulcinj but we can also arrange call outs all over the coast or even meet your boat in open water.</p>
                    </Text>
                </Grid.Col>
                <Grid.Col span={12} p={0} mb={50}>
                    <Title style={{fontSize: isSmallScreen ? 50 : 80, fontWeight: 700, textAlign: "center"}} pt={50} pb={isSmallScreen ? 30 : 50}>OUR TEAM</Title>
                    <Group direction="column" align={isSmallScreen ? "unset" : "center"} spacing={60}>
                        <Grid styles={() => ({root: {margin: "0 !important", width: isSmallScreen ? "100%" : "unset"}})}>
                            <Grid.Col span={isSmallScreen ? 12 : 6} p={isSmallScreen ? 30 : 0}>
                                <Group direction="column" style={{width: isSmallScreen ? "100%" : 500}} spacing={0}>
                                    <Title style={{fontSize: isSmallScreen ? 38 : 45, fontWeight: 500}}>Darko Kastratović</Title>
                                    <Text style={{fontSize: isSmallScreen ? 22 : 26, fontWeight: 500, textAlign: "left"}}>Founder of FLY E-Foil School</Text>
                                    <Text style={{fontSize: isSmallScreen ? 22 : 26, fontWeight: 500, textAlign: "left"}}>Promotion rider</Text>
                                    <Text style={{fontSize: isSmallScreen ? 22 : 26, fontWeight: 500, textAlign: "left"}}>E-Foil & Kite Instructor</Text>
                                    <Space h={30}/>
                                    <Group spacing={5} align="center">
                                        <Text style={{fontSize: 20, fontWeight: 500, textAlign: "left"}}><b>Date of birth</b>:</Text>
                                        <Text style={{fontSize: 20, fontWeight: 400}}>22-11-1987</Text>
                                    </Group>
                                    <Group spacing={5} align="center">
                                        <Text style={{fontSize: 20, fontWeight: 500, textAlign: "left"}}><b>Nationality</b>:</Text>
                                        <Text style={{fontSize: 20, fontWeight: 400}}>SRB</Text>
                                    </Group>
                                    <Group spacing={5} align="center">
                                        <Text style={{fontSize: 20, fontWeight: 500, textAlign: "left"}}><b>Living in</b>:</Text>
                                        <Text style={{fontSize: 20, fontWeight: 400}}>Belgrade, Serbia</Text>
                                    </Group>
                                    <Group spacing={5} align="center">
                                        <Text style={{fontSize: 20, fontWeight: 500, textAlign: "left"}}><b>Languages</b>:</Text>
                                        <Text style={{fontSize: 20, fontWeight: 400}}>Serbian, English</Text>
                                    </Group>
                                    <Group spacing={5} align="center">
                                        <Text style={{fontSize: 20, fontWeight: 500, textAlign: "left"}}><b>Licenses</b>:</Text>
                                        <Text style={{fontSize: 20, fontWeight: 400}}>ISIA level 1, snowboarding coach, IKO level 2 kiteboarding coach</Text>
                                    </Group>
                                </Group>
                            </Grid.Col>
                            <Grid.Col span={isSmallScreen ? 12 : 6}>
                                <Image
                                    src={AboutUs1}
                                    radius={80}
                                    width={isSmallScreen ? 300 : 400}
                                    height={isSmallScreen ? 300 : 400}
                                    styles={() => ({
                                        image: {
                                            objectPosition: "top",
                                            marginLeft: "auto",
                                            marginRight: "auto"
                                        }
                                    })}
                                />
                            </Grid.Col>
                        </Grid>
                        <Grid styles={() => ({root: {margin: "0 !important", width: isSmallScreen ? "100%" : "unset"}})}>
                            <Grid.Col span={isSmallScreen ? 12 : 6} p={isSmallScreen ? 30 : 0}>
                                <Group direction="column" style={{width: isSmallScreen ? "100%" : 500}} spacing={0}>
                                    <Title style={{fontSize: isSmallScreen ? 38 : 45, fontWeight: 500}}>Nemanja Škobić</Title>
                                    <Text style={{fontSize: isSmallScreen ? 22 : 26, fontWeight: 500, textAlign: "left"}}>Founder of Dolcinium Kite School</Text>
                                    <Text style={{fontSize: isSmallScreen ? 22 : 26, fontWeight: 500, textAlign: "left"}}>E-Foil & Kite Instructor</Text>
                                    <Space h={30}/>
                                    <Group spacing={5} align="center">
                                        <Text style={{fontSize: 20, fontWeight: 500, textAlign: "left"}}><b>Date of birth</b>:</Text>
                                        <Text style={{fontSize: 20, fontWeight: 400}}>06-09-1997</Text>
                                    </Group>
                                    <Group spacing={5} align="center">
                                        <Text style={{fontSize: 20, fontWeight: 500, textAlign: "left"}}><b>Nationality</b>:</Text>
                                        <Text style={{fontSize: 20, fontWeight: 400}}>MNE</Text>
                                    </Group>
                                    <Group spacing={5} align="center">
                                        <Text style={{fontSize: 20, fontWeight: 500, textAlign: "left"}}><b>Living in</b>:</Text>
                                        <Text style={{fontSize: 20, fontWeight: 400}}>Ulcinj, Montenegro</Text>
                                    </Group>
                                    <Group spacing={5} align="center">
                                        <Text style={{fontSize: 20, fontWeight: 500, textAlign: "left"}}><b>Languages</b>:</Text>
                                        <Text style={{fontSize: 20, fontWeight: 400}}>Serbian, English</Text>
                                    </Group>
                                    <Group spacing={5} align="center">
                                        <Text style={{fontSize: 20, fontWeight: 500, textAlign: "left"}}><b>Licenses</b>:</Text>
                                        <Text style={{fontSize: 20, fontWeight: 400}}>ISIA level 2, IKO level 2</Text>
                                    </Group>
                                </Group>
                            </Grid.Col>
                            <Grid.Col span={isSmallScreen ? 12 : 6}>
                                <Image
                                    src={AboutUs2}
                                    radius={80}
                                    width={isSmallScreen ? 300 : 400}
                                    height={isSmallScreen ? 300 : 400}
                                    styles={() => ({
                                        image: {
                                            objectPosition: "top",
                                            marginLeft: "auto",
                                            marginRight: "auto"
                                        }
                                    })}
                                />
                            </Grid.Col>
                        </Grid>
                        <Grid styles={() => ({root: {margin: "0 !important", width: isSmallScreen ? "100%" : "unset"}})}>
                            <Grid.Col span={isSmallScreen ? 12 : 6} p={isSmallScreen ? 30 : 0}>
                                <Group direction="column" style={{width: isSmallScreen ? "100%" : 500}} spacing={0}>
                                    <Title style={{fontSize: isSmallScreen ? 38 : 45, fontWeight: 500}}>Janko Kolarević</Title>
                                    <Text style={{fontSize: isSmallScreen ? 22 : 26, fontWeight: 500, textAlign: "left"}}>E-Foil Instructor</Text>
                                    <Space h={30}/>
                                    <Group spacing={5} align="center">
                                        <Text style={{fontSize: 20, fontWeight: 500, textAlign: "left"}}><b>Date of birth</b>:</Text>
                                        <Text style={{fontSize: 20, fontWeight: 400}}>06-09-1997</Text>
                                    </Group>
                                    <Group spacing={5} align="center">
                                        <Text style={{fontSize: 20, fontWeight: 500, textAlign: "left"}}><b>Nationality</b>:</Text>
                                        <Text style={{fontSize: 20, fontWeight: 400}}>SRB</Text>
                                    </Group>
                                    <Group spacing={5} align="center">
                                        <Text style={{fontSize: 20, fontWeight: 500, textAlign: "left"}}><b>Living in</b>:</Text>
                                        <Text style={{fontSize: 20, fontWeight: 400}}>Čačak, Serbia</Text>
                                    </Group>
                                    <Group spacing={5} align="center">
                                        <Text style={{fontSize: 20, fontWeight: 500, textAlign: "left"}}><b>Languages</b>:</Text>
                                        <Text style={{fontSize: 20, fontWeight: 400}}>Serbian, English</Text>
                                    </Group>
                                    <Group spacing={5} align="center">
                                        <Text style={{fontSize: 20, fontWeight: 500, textAlign: "left"}}><b>Licenses</b>:</Text>
                                        <Text style={{fontSize: 20, fontWeight: 400}}>ISIA level 3, snowboarding coach</Text>
                                    </Group>
                                </Group>
                            </Grid.Col>
                            <Grid.Col span={isSmallScreen ? 12 : 6}>
                                <Image
                                    src={AboutUs3}
                                    radius={80}
                                    width={isSmallScreen ? 300 : 400}
                                    height={isSmallScreen ? 300 : 400}
                                    styles={() => ({
                                        image: {
                                            objectPosition: "top",
                                            marginLeft: "auto",
                                            marginRight: "auto"
                                        }
                                    })}
                                />
                            </Grid.Col>
                        </Grid>
                    </Group>
                </Grid.Col>
                <Grid.Col span={12} style={{backgroundColor: "#C4C4C4"}} p={isSmallScreen ? 25 : 40} pl={isSmallScreen ? 20 : 250} pr={isSmallScreen ? 20 : 250}>
                    <Center>
                        <Title style={{fontSize: isSmallScreen ? 26 : 34, fontWeight: 500, textAlign: "center"}}>
                            If you have water sport school or rental and are looking to expand we offer you a collaboration with our team and our E-Foil boards
                        </Title>
                    </Center>
                </Grid.Col>
            </Grid>
            <Footer/>
        </>
    );
};

export default AboutUs;
