import { Burger, Grid, Header, Image, Modal, Text } from '@mantine/core';
import React, { FunctionComponent, useEffect, useState } from 'react';
import FlyEFoil from "./resources/Fly.svg";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mantine/hooks";
import { MdClose } from "react-icons/all";

const Navbar: FunctionComponent<any> = (props) => {
    let navigate = useNavigate();
    const {pathname} = useLocation();

    useEffect(() => {
        document.body.scrollTo(0, 0)
    }, [pathname]);

    const [navigationOpened, setNavigationOpened] = useState<boolean>(false);

    const isSmallScreen = useMediaQuery('(max-width: 500px)');

    const goToSRBMNE = () => {
        navigate("Schools", {replace: true})
        setNavigationOpened(false);
    }

    const goToContact = () => {
        navigate("Contact", {replace: true})
        setNavigationOpened(false);
    }

    const goToHomePage = () => {
        navigate("/", {replace: true})
        setNavigationOpened(false);
    }

    const goToDemo = () => {
        navigate("Demo", {replace: true})
        setNavigationOpened(false);
    }

    const goToTeamBuilding = () => {
        navigate("TeamBuilding", {replace: true})
        setNavigationOpened(false);
    }

    const goToAboutUs = () => {
        navigate("AboutUs", {replace: true})
        setNavigationOpened(false);
    }

    return (
        <>
            <div style={{width: "100%", height: "100%", position: "relative"}}>
                <Header
                    styles={() => ({
                        root: {
                            borderBottom: "unset",
                            backgroundColor: "unset"
                        }
                    })}
                    style={{
                        position: "absolute"
                    }}
                    height={70}
                    mt={50}
                    pl={50}
                    pr={50}
                >
                    <div style={{display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', height: '100%'}}>
                        <Image
                            width={isSmallScreen ? 70 : 125}
                            height={isSmallScreen ? 70 : 125}
                            src={FlyEFoil}
                            style={{cursor: "pointer"}}
                            onClick={() => goToHomePage()}
                        />
                        {navigationOpened ? null : (<Burger
                            size="lg"
                            color="white"
                            opened={navigationOpened}
                            onClick={(e) => setNavigationOpened(!navigationOpened)}
                        />)}
                    </div>
                </Header>
                <Outlet/>
            </div>
            <Modal
                styles={() => ({
                    root: {
                        width: "100%",
                        height: "100%"
                    },
                    modal: {
                        backgroundColor: "unset",
                        boxShadow: "unset",
                        width: "100%",
                        height: "100%"
                    },
                    body: {
                        width: "100%",
                        verticalAlign: "middle"
                    }
                })}
                withCloseButton={false}
                opened={navigationOpened}
                onClose={() => setNavigationOpened(false)}
            >
                <Grid>
                    <Grid.Col>
                        <Text
                            color="white"
                            style={{
                                width: "max-content",
                                fontSize: 30,
                                fontWeight: "bold",
                                marginLeft: "auto"
                            }}
                        >
                            <MdClose
                                style={{
                                    cursor: "pointer"
                                }}
                                onClick={() => setNavigationOpened(false)}
                                size={44}
                            />
                        </Text>
                    </Grid.Col>
                    <Grid.Col>
                        <Text
                            color="white"
                            onClick={() => goToHomePage()}
                            style={{
                                cursor: "pointer",
                                width: "max-content",
                                fontSize: 30,
                                fontWeight: "bold",
                                marginLeft: "auto",
                                marginRight: "auto"
                            }}
                        >
                            HOMEPAGE
                        </Text>
                    </Grid.Col>
                    <Grid.Col>
                        <Text
                            color="white"
                            onClick={() => goToSRBMNE()}
                            style={{
                                cursor: "pointer",
                                width: "max-content",
                                fontSize: 30,
                                fontWeight: "bold",
                                marginLeft: "auto",
                                marginRight: "auto"
                            }}
                        >
                            SCHOOLS
                        </Text>
                    </Grid.Col>
                    <Grid.Col>
                        <Text
                            color="white"
                            onClick={() => goToDemo()}
                            style={{
                                cursor: "pointer",
                                width: "max-content",
                                fontSize: 30,
                                fontWeight: "bold",
                                marginLeft: "auto",
                                marginRight: "auto"
                            }}
                        >
                            DEMO
                        </Text>
                    </Grid.Col>
                    <Grid.Col>
                        <Text
                            color="white"
                            onClick={() => goToTeamBuilding()}
                            style={{
                                cursor: "pointer",
                                width: "max-content",
                                fontSize: 30,
                                fontWeight: "bold",
                                marginLeft: "auto",
                                marginRight: "auto"
                            }}
                        >
                            TEAM BUILDING
                        </Text>
                    </Grid.Col>
                    <Grid.Col>
                        <Text
                            color="white"
                            onClick={() => goToAboutUs()}
                            style={{
                                cursor: "pointer",
                                width: "max-content",
                                fontSize: 30,
                                fontWeight: "bold",
                                marginLeft: "auto",
                                marginRight: "auto"
                            }}
                        >
                            ABOUT US
                        </Text>
                    </Grid.Col>
                    <Grid.Col>
                        <Text
                            color="white"
                            onClick={() => goToContact()}
                            style={{
                                cursor: "pointer",
                                width: "max-content",
                                fontSize: 30,
                                fontWeight: "bold",
                                marginLeft: "auto",
                                marginRight: "auto"
                            }}
                        >
                            CONTACT
                        </Text>
                    </Grid.Col>
                </Grid>
            </Modal>
        </>
    );
};

export default Navbar;
