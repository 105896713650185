import React from 'react';
import { AppShell, MantineProvider } from '@mantine/core';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import Navbar from "./Navbar";
import SRBSchool from "./pages/SRBSchool";
import MNESchool from "./pages/MNESchool";
import Schools from "./pages/Schools";
import Contact from "./pages/Contact";
import Demo from "./pages/Demo";
import TeamBuilding from "./pages/TeamBuilding";
import AboutUs from "./pages/AboutUs";

function App() {

    return (
        <BrowserRouter>
            <MantineProvider theme={{
                fontFamily: 'Roboto',
                headings: {
                    fontFamily: 'Roboto'
                }
            }} withGlobalStyles withNormalizeCSS>
                <AppShell
                    styles={(theme) => ({
                        root: {
                            height: "100vh"
                        },
                        body: {
                            height: "100vh"
                        },
                        main: {
                            padding: 0
                        }
                    })}
                >
                    <Routes>
                        <Route path="/" element={<Navbar/>}>
                            <Route index element={<HomePage/>}/>
                            <Route path="Schools" element={<Schools/>}/>
                            <Route path="Schools/Serbia" element={<SRBSchool/>}/>
                            <Route path="Schools/Serbia/Contact" element={<Contact/>}/>
                            <Route path="Schools/Montenegro" element={<MNESchool/>}/>
                            <Route path="Schools/Montenegro/Contact" element={<Contact/>}/>
                            <Route path="Demo" element={<Demo/>}/>
                            <Route path="Demo/Contact" element={<Contact/>}/>
                            <Route path="TeamBuilding" element={<TeamBuilding/>}/>
                            <Route path="TeamBuilding/Contact" element={<Contact/>}/>
                            <Route path="AboutUs" element={<AboutUs/>}/>
                            <Route path="AboutUs/Contact" element={<Contact/>}/>
                            <Route path="Contact" element={<Contact/>}/>
                            <Route path="*" element={<>NO MATCH</>}/>
                        </Route>
                    </Routes>
                </AppShell>
            </MantineProvider>
        </BrowserRouter>
    );
}

export default App;
