import React, { FunctionComponent } from 'react';
import { Center, Grid, Group, Image, Space, Text, Title } from "@mantine/core";
import EFoilButton from "../components/EFoilButton";
import DemoShows from "../resources/demo_shows.jpg";
import Demo1 from "../resources/demo_1.png";
import Demo2 from "../resources/demo_2.jpg";
import Demo3 from "../resources/demo_3.jpg";
import Footer from "../Footer";
import { useMediaQuery } from "@mantine/hooks";
import { useNavigate } from "react-router-dom";

const Demo: FunctionComponent<any> = (props) => {
    let navigate = useNavigate();

    const isSmallScreen = useMediaQuery('(max-width: 500px)');

    const goToContact = () => {
        navigate("Contact", {replace: true})
    }

    return (
        <>
            <div
                style={{
                    position: "relative",
                    width: "100%",
                    height: "100%",
                    background: "linear-gradient( rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25) ), url(" + DemoShows + ")",
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat'
                }}
            >
                <Center style={{height: "100%"}}>
                    <Title style={{color: "white", fontSize: isSmallScreen ? "64px" : "100px", fontWeight: 700, textAlign: "center"}}>DEMO</Title>
                </Center>
            </div>
            <Grid m={0}>
                <Grid.Col span={12} p={0} style={{minHeight: isSmallScreen ? "unset" : "620px"}}>
                    <Group
                        position="center"
                        grow
                        p={0}
                        spacing={0}
                        direction={isSmallScreen ? "column" : "row"}
                        align="center"
                        style={{height: isSmallScreen ? "unset" : "100%"}}
                    >
                        <Center>
                            <Group
                                pl={isSmallScreen ? 55 : 110}
                                pr={isSmallScreen ? 55 : 110}
                                pt={30}
                                pb={30}
                                grow
                                spacing={0}
                                position="center"
                                direction="column"
                                align="flex-start"
                            >
                                <Title mb={20} style={{fontWeight: 700, fontSize: isSmallScreen ? 30 : 40}}>TRY BEFORE YOU BUY</Title>
                                <Text style={{fontWeight: 400, fontSize: isSmallScreen ? 20 : 24}}>
                                    Liftfoils is a premium e-Foil and hydrofoil brand with a dedicated innovator in both performance and technology. They build products that provide truly incredible
                                    experiences on the water.
                                </Text>
                                <Space h="lg"/>
                                <Text mb={30} style={{fontWeight: 400, fontSize: isSmallScreen ? 20 : 24}}>Reach out to us to arrange a demo and to guide you in purchasing your first E-Foil. We will help you with the purchase process of your e-Foil
                                    and with the set-up of
                                    your board.
                                </Text>
                                <EFoilButton color="black" onClick={() => goToContact()}/>
                            </Group>
                        </Center>
                        <Image styles={() => ({
                            root: {
                                height: "100%"
                            },
                            image: {
                                height: "100% !important"
                            },
                            figure: {
                                height: "100%"
                            },
                            imageWrapper: {
                                height: "100%"
                            }
                        })} src={Demo1}/>
                    </Group>
                </Grid.Col>
                <Grid.Col span={12} p={0} style={{minHeight: isSmallScreen ? "unset" : "620px"}}>
                    <Group
                        position="center"
                        grow
                        p={0}
                        spacing={0}
                        direction={isSmallScreen ? "column" : "row"}
                        align="center"
                        style={{height: isSmallScreen ? "unset" : "100%", flexDirection: isSmallScreen ? "column-reverse" : "unset"}}
                    >
                        <Image styles={() => ({
                            root: {
                                height: "100%"
                            },
                            image: {
                                height: "100% !important"
                            },
                            figure: {
                                height: "100%"
                            },
                            imageWrapper: {
                                height: "100%"
                            }
                        })} src={Demo2}/>
                        <Center>
                            <Group
                                pl={isSmallScreen ? 55 : 110}
                                pr={isSmallScreen ? 55 : 110}
                                pt={30}
                                pb={30}
                                grow
                                spacing={0}
                                position="center"
                                direction="column"
                                align="flex-start"
                            >
                                <Title mb={20} style={{fontWeight: 700, fontSize: isSmallScreen ? 30 : 40}}>DEMO SHOWS</Title>
                                <Text mb={30} style={{fontWeight: 400, fontSize: isSmallScreen ? 20 : 24}}>
                                    We offer promotional rides by our team for various events such as festivals, parties, boat shows or any other type of request.
                                </Text>
                                <EFoilButton color="black" onClick={() => goToContact()}/>
                            </Group>
                        </Center>
                    </Group>
                </Grid.Col>
                <Grid.Col span={12} p={0} style={{minHeight: isSmallScreen ? "unset" : "620px"}}>
                    <Group
                        position="center"
                        grow
                        p={0}
                        spacing={0}
                        direction={isSmallScreen ? "column" : "row"}
                        align="center"
                        style={{height: isSmallScreen ? "unset" : "100%"}}
                    >
                        <Center>
                            <Group
                                style={{height: isSmallScreen ? "max-content" : "unset"}}
                                pl={isSmallScreen ? 55 : 110}
                                pr={isSmallScreen ? 55 : 110}
                                pt={30}
                                pb={30}
                                grow
                                spacing={0}
                                position="center"
                                direction="column"
                                align="flex-start"
                            >
                                <Title mb={20} style={{fontWeight: 700, fontSize: isSmallScreen ? 30 : 40}}>SCHOOL & RENTAL COLLABORATION</Title>
                                <Text mb={30} style={{fontWeight: 400, fontSize: isSmallScreen ? 20 : 24}}>
                                    We offer promotional rides by our team for various events such as festivals, parties, boat shows or any other type of request.
                                </Text>
                                <EFoilButton color="black" onClick={() => goToContact()}/>
                            </Group>
                        </Center>
                        <Image styles={() => ({
                            root: {
                                height: "100%"
                            },
                            image: {
                                height: "100% !important"
                            },
                            figure: {
                                height: "100%"
                            },
                            imageWrapper: {
                                height: "100%"
                            }
                        })} src={Demo3}/>
                    </Group>
                </Grid.Col>
            </Grid>
            <Footer/>
        </>
    );
};

export default Demo;
