import React, { FunctionComponent } from 'react';
import { Center, Grid, Text } from "@mantine/core";
import EFoilButton from "../components/EFoilButton";
import SRBSchool from "../resources/SRB_school.png";
import MNESchool from "../resources/MNE_school.png";
import { useMediaQuery } from "@mantine/hooks";
import Footer from "../Footer";
import { Link, useNavigate } from "react-router-dom";

const Schools: FunctionComponent<any> = (props) => {
    let navigate = useNavigate();

    const isSmallScreen = useMediaQuery('(max-width: 500px)');

    const goToSRBSchool = () => {
        navigate("Serbia", {replace: true})
    }

    const goToMNESchool = () => {
        navigate("Montenegro", {replace: true})
    }

    return (
        <>
            <Grid style={{height: isSmallScreen ? "unset" : "100%"}}>
                <Grid.Col
                    style={{
                        objectFit: "cover",
                        position: "relative",
                        background: "linear-gradient( rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25) ), url(" + SRBSchool + ")",
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        textAlign: 'center',
                        minHeight: isSmallScreen ? 540 : "unset"
                    }}
                    span={isSmallScreen ? 12 : 6}
                    p={100}
                >
                    <Center style={{display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%'}}>
                        <Text
                            style={{
                                fontSize: isSmallScreen ? 48 : 80,
                                fontWeight: 'bold'
                            }}
                            color="white"
                        >
                            SERBIA
                        </Text>
                        <Link to={"Serbia"} replace>
                            <EFoilButton onClick={() => goToSRBSchool()}/>
                        </Link>
                    </Center>
                </Grid.Col>
                <Grid.Col
                    style={{
                        objectFit: "cover",
                        position: "relative",
                        background: "linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(" + MNESchool + ")",
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        textAlign: 'center',
                        minHeight: isSmallScreen ? 540 : "unset"
                    }}
                    span={isSmallScreen ? 12 : 6}
                    p={100}
                >
                    <Center style={{display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%'}}>
                        <Text
                            style={{
                                fontSize: isSmallScreen ? 48 : 80,
                                fontWeight: 'bold'
                            }}
                            color="white"
                        >
                            MONTENEGRO
                        </Text>
                        <EFoilButton onClick={goToMNESchool}/>
                    </Center>
                </Grid.Col>
            </Grid>
            <Footer/>
        </>
    );
};

export default Schools;
