import React, { FunctionComponent } from 'react';
import { Center, Grid, Group, Image, Text, Title } from "@mantine/core";
import EFoilButton from "../components/EFoilButton";
import MNESchoolMain from "../resources/mne-school-main.jpg";
import MNESchool1 from "../resources/mne-school-1.jpg";
import MNESchool2 from "../resources/mne-school-2.jpg";
import MNESchool3 from "../resources/mne-school-3.jpg";
import MNESchool4 from "../resources/mne-school-4.jpg";
import MNESchool5 from "../resources/mne-school-5.png";
import Footer from "../Footer";
import { useMediaQuery } from "@mantine/hooks";
import { useNavigate } from "react-router-dom";

const MNESchool: FunctionComponent<any> = (props) => {
    let navigate = useNavigate();

    const isSmallScreen = useMediaQuery('(max-width: 500px)');

    const goToContact = () => {
        navigate("Contact", {replace: true})
    }

    return (
        <>
            <div
                style={{
                    position: "relative",
                    width: "100%",
                    height: "100%",
                    background: "linear-gradient( rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25) ), url(" + MNESchoolMain + ")",
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat'
                }}
            >
                <Grid
                    p={30}
                    style={{height: "100%"}}
                >
                    {isSmallScreen ? (
                        <>
                            <Grid.Col span={isSmallScreen ? 12 : 6}
                                      style={{
                                          display: "flex",
                                          alignItems: "flex-end"
                                      }}
                            >
                                <Group
                                    direction="column"
                                    style={{
                                        maxWidth: 400
                                    }}
                                >
                                    <Text
                                        style={{
                                            fontSize: 40,
                                            fontWeight: 700,
                                            color: "white",
                                            lineHeight: isSmallScreen ? "28px" : "117px"
                                        }}
                                    >
                                        LESSONS /
                                    </Text>
                                    <Text
                                        style={{
                                            fontSize: 40,
                                            fontWeight: 700,
                                            color: "white",
                                            lineHeight: isSmallScreen ? "28px" : "117px"
                                        }}
                                    >
                                        TOURS /
                                    </Text>
                                    <Text
                                        style={{
                                            fontSize: 40,
                                            fontWeight: 700,
                                            color: "white",
                                            lineHeight: isSmallScreen ? "28px" : "117px"
                                        }}
                                    >
                                        RENT /
                                    </Text>
                                    <Text
                                        style={{
                                            fontSize: 30,
                                            fontWeight: "bold",
                                            color: "white"
                                        }}
                                    >
                                        MONTENEGRO
                                    </Text>
                                </Group>
                            </Grid.Col>
                        </>
                    ) : (
                        <>
                            <Grid.Col
                                span={isSmallScreen ? 12 : 6}
                                style={{
                                    display: "flex",
                                    alignItems: "flex-end"
                                }}
                            >
                                <Text
                                    style={{
                                        fontSize: 40,
                                        fontWeight: "bold",
                                        color: "white",
                                        padding: 10,
                                        paddingLeft: 20
                                    }}
                                >
                                    MONTENEGRO
                                </Text>
                            </Grid.Col>
                            <Grid.Col span={isSmallScreen ? 12 : 6}
                                      style={{
                                          display: "flex",
                                          alignItems: "flex-end"
                                      }}
                            >
                                <Group
                                    style={{
                                        maxWidth: 400,
                                        marginLeft: "auto",
                                        paddingBottom: 20
                                    }}
                                >
                                    <Text
                                        style={{
                                            fontSize: 80,
                                            fontWeight: 700,
                                            color: "white",
                                            lineHeight: isSmallScreen ? "54px" : "80px"
                                        }}
                                    >
                                        LESSONS /
                                    </Text>
                                    <Text
                                        style={{
                                            fontSize: 80,
                                            fontWeight: 700,
                                            color: "white",
                                            lineHeight: isSmallScreen ? "54px" : "80px"
                                        }}
                                    >
                                        TOURS /
                                    </Text>
                                    <Text
                                        style={{
                                            fontSize: 80,
                                            fontWeight: 700,
                                            color: "white",
                                            lineHeight: isSmallScreen ? "54px" : "80px"
                                        }}
                                    >
                                        RENT /
                                    </Text>
                                </Group>
                            </Grid.Col>
                        </>
                    )}
                </Grid>
            </div>
            <Grid m={0}>
                <Grid.Col span={12} p={0} style={{maxHeight: isSmallScreen ? "unset" : "620px"}}>
                    <Group
                        position="center"
                        grow
                        p={0}
                        spacing={0}
                        direction={isSmallScreen ? "column" : "row"}
                        align="center"
                        style={{height: isSmallScreen ? "unset" : "100%", flexDirection: isSmallScreen ? "column-reverse" : "unset"}}
                    >
                        <Image src={MNESchool1} styles={() => ({
                            root: {
                                height: "100%"
                            },
                            image: {
                                height: "100% !important"
                            },
                            figure: {
                                height: "100%"
                            },
                            imageWrapper: {
                                height: "100%"
                            }
                        })}/>
                        <Center>
                            <Group
                                pl={isSmallScreen ? 55 : 110}
                                pr={isSmallScreen ? 55 : 110}
                                pt={30}
                                pb={30}
                                grow
                                spacing={0}
                                position="center"
                                direction="column"
                                align="flex-start"
                            >
                                <Group
                                    mb={20}
                                    direction="column"
                                    spacing={0}
                                >
                                    <Title style={{fontWeight: 700, fontSize: isSmallScreen ? 30 : 40}}>1-1 LESSONS</Title>
                                    <Group>
                                        <Title style={{width: "max-content", fontWeight: 500, fontSize: isSmallScreen ? 30 : 40}}><i>FROM</i></Title>
                                        <Text style={{fontWeight: 300, fontSize: isSmallScreen ? 25 : 35}}>
                                            80 € / 30 MINUTES
                                        </Text>
                                    </Group>
                                </Group>
                                <ul style={{marginBottom: 30}}>
                                    <li style={{fontSize: "20px", fontWeight: 400}}>
                                        10 minutes of preparation on the beach providing you with necessary information
                                        about the board before you jump in the water
                                    </li>
                                    <li style={{fontSize: "20px", fontWeight: 400}}>20 minutes in the water</li>
                                    <li style={{fontSize: "20px", fontWeight: 400}}>Photos taken</li>
                                </ul>
                                <EFoilButton color="black" onClick={() => goToContact()}/>
                            </Group>
                        </Center>
                    </Group>
                </Grid.Col>
                <Grid.Col span={12} p={0} style={{maxHeight: isSmallScreen ? "unset" : "620px"}}>
                    <Group
                        position="center"
                        grow
                        p={0}
                        spacing={0}
                        direction={isSmallScreen ? "column" : "row"}
                        align="center"
                        style={{height: isSmallScreen ? "unset" : "100%"}}
                    >
                        <Center>
                            <Group
                                pl={isSmallScreen ? 55 : 110}
                                pr={isSmallScreen ? 55 : 110}
                                pt={30}
                                pb={30}
                                grow
                                spacing={0}
                                position="center"
                                direction="column"
                                align="flex-start"
                            >
                                <Group
                                    mb={20}
                                    direction="column"
                                    spacing={0}
                                >
                                    <Title style={{fontWeight: 700, fontSize: isSmallScreen ? 30 : 40}}>GROUP LESSONS</Title>
                                    <Title style={{fontWeight: 500, fontSize: isSmallScreen ? 30 : 40}}><i>SHARED E-FOIL BOARD</i></Title>
                                </Group>
                                <ul style={{marginBottom: 30}}>
                                    <li style={{fontSize: "20px", fontWeight: 400}}>
                                        Price is divided based on the number of people sharing 1 board
                                    </li>
                                    <li style={{fontSize: "20px", fontWeight: 400}}>60 minutes minimum</li>
                                    <li style={{fontSize: "20px", fontWeight: 400}}>
                                        10 minutes of preparation on the beach providing you with necessary information about the board before you jump in the water
                                    </li>
                                    <li style={{fontSize: "20px", fontWeight: 400}}>50 minutes in the water equally divided</li>
                                    <li style={{fontSize: "20px", fontWeight: 400}}>Photos taken</li>
                                </ul>
                                <EFoilButton color="black" onClick={() => goToContact()}/>
                            </Group>
                        </Center>
                        <Image src={MNESchool2} styles={() => ({
                            root: {
                                height: "100%"
                            },
                            image: {
                                height: "100% !important"
                            },
                            figure: {
                                height: "100%"
                            },
                            imageWrapper: {
                                height: "100%"
                            }
                        })}/>
                    </Group>
                </Grid.Col>
                <Grid.Col span={12} p={0} style={{maxHeight: isSmallScreen ? "unset" : "620px"}}>
                    <Group
                        position="center"
                        grow
                        p={0}
                        spacing={0}
                        direction={isSmallScreen ? "column" : "row"}
                        align="center"
                        style={{height: isSmallScreen ? "unset" : "100%", flexDirection: isSmallScreen ? "column-reverse" : "unset"}}
                    >
                        <Image src={MNESchool3} styles={() => ({
                            root: {
                                height: "100%"
                            },
                            image: {
                                height: "100% !important"
                            },
                            figure: {
                                height: "100%"
                            },
                            imageWrapper: {
                                height: "100%"
                            }
                        })}/>
                        <Center>
                            <Group
                                pl={isSmallScreen ? 55 : 110}
                                pr={isSmallScreen ? 55 : 110}
                                pt={30}
                                pb={30}
                                grow
                                spacing={0}
                                position="center"
                                direction="column"
                                align="flex-start"
                            >
                                <Group
                                    mb={20}
                                    direction="column"
                                    spacing={0}
                                >
                                    <Title style={{fontWeight: 700, fontSize: isSmallScreen ? 30 : 40}}>GROUP LESSONS</Title>
                                    <Title style={{fontWeight: 500, fontSize: isSmallScreen ? 28 : 40}}><i>INDIVIDUAL E-FOIL</i></Title>
                                    <Group>
                                        <Title style={{width: "max-content", fontWeight: 500, fontSize: isSmallScreen ? 28 : 40}}><i>FROM</i></Title>
                                        <Text style={{fontWeight: 300, fontSize: isSmallScreen ? 25 : 35}}>
                                            70 € / 30 MINUTES
                                        </Text>
                                    </Group>
                                </Group>
                                <ul style={{marginBottom: 30}}>
                                    <li style={{fontSize: "20px", fontWeight: 400}}>Up to 2 people</li>
                                    <li style={{fontSize: "20px", fontWeight: 400}}>
                                        10 minutes of preparation on the beach providing you with necessary information about the board before you jump in the water
                                    </li>
                                    <li style={{fontSize: "20px", fontWeight: 400}}>20 minutes in the water</li>
                                    <li style={{fontSize: "20px", fontWeight: 400}}>Photos taken</li>
                                </ul>
                                <EFoilButton color="black" onClick={() => goToContact()}/>
                            </Group>
                        </Center>
                    </Group>
                </Grid.Col>
                <Grid.Col span={12} p={0} style={{maxHeight: isSmallScreen ? "unset" : "620px"}}>
                    <Group
                        position="center"
                        grow
                        p={0}
                        spacing={0}
                        direction={isSmallScreen ? "column" : "row"}
                        align="center"
                        style={{height: isSmallScreen ? "unset" : "100%"}}
                    >
                        <Center>
                            <Group
                                pl={isSmallScreen ? 55 : 110}
                                pr={isSmallScreen ? 55 : 110}
                                pt={30}
                                pb={30}
                                grow
                                spacing={0}
                                position="center"
                                direction="column"
                                align="flex-start"
                            >
                                <Group
                                    mb={20}
                                    direction="column"
                                    spacing={0}
                                >
                                    <Title style={{fontWeight: 700, fontSize: isSmallScreen ? 30 : 40}}>TOURS</Title>
                                    <Group>
                                        <Text style={{fontSize: "32px", fontWeight: 300}}>
                                            2-4 PEOPLE
                                        </Text>
                                    </Group>
                                </Group>
                                <Text style={{fontSize: "20px", fontWeight: 400}}>
                                    We offer group lessons to explore the beauty of the Mediterranean sea from a different point of view with your family and friends
                                </Text>
                                <ul style={{marginBottom: 30}}>
                                    <li style={{fontSize: "20px", fontWeight: 400}}>
                                        Minimum experience lasts about 1.5 hours but can be modulated according to the level and the customer’s request
                                    </li>
                                    <li style={{fontSize: "20px", fontWeight: 400}}>
                                        Tour place will be agreed with the customer based on the selected time, sea conditions and their level of experience
                                    </li>
                                    <li style={{fontSize: "20px", fontWeight: 400}}>
                                        The price of the tours varies according to the requested location, boat and the number of people
                                    </li>
                                </ul>
                                <EFoilButton color="black" onClick={() => goToContact()}/>
                            </Group>
                        </Center>
                        <Image src={MNESchool4} styles={() => ({
                            root: {
                                height: "100%"
                            },
                            image: {
                                height: "100% !important"
                            },
                            figure: {
                                height: "100%"
                            },
                            imageWrapper: {
                                height: "100%"
                            }
                        })}/>
                    </Group>
                </Grid.Col>
                <Grid.Col span={12} p={0} style={{maxHeight: isSmallScreen ? "unset" : "620px"}}>
                    <Group
                        position="center"
                        grow
                        p={0}
                        spacing={0}
                        direction={isSmallScreen ? "column" : "row"}
                        align="center"
                        style={{height: isSmallScreen ? "unset" : "100%", flexDirection: isSmallScreen ? "column-reverse" : "unset"}}
                    >
                        <Image src={MNESchool5} styles={() => ({
                            root: {
                                height: "100%"
                            },
                            image: {
                                height: "100% !important"
                            },
                            figure: {
                                height: "100%"
                            },
                            imageWrapper: {
                                height: "100%"
                            }
                        })}/>
                        <Center>
                            <Group
                                pl={isSmallScreen ? 55 : 110}
                                pr={isSmallScreen ? 55 : 110}
                                pt={30}
                                pb={30}
                                grow
                                spacing={0}
                                position="center"
                                direction="column"
                                align="flex-start"
                            >
                                <Group
                                    mb={20}
                                    direction="column"
                                    spacing={0}
                                >
                                    <Title style={{fontWeight: 700, fontSize: isSmallScreen ? 30 : 40}}>RENT</Title>
                                    <Title style={{fontWeight: 500, fontSize: isSmallScreen ? 22 : 40}}><i>DAILY BOAT PACKAGES</i></Title>
                                </Group>
                                <Text style={{fontSize: "20px", fontWeight: 400}} mb={5}>
                                    We will tailor a package to cater your exact needs and, the only thing left is for you to have fun
                                </Text>

                                <ul style={{marginBottom: 30}}>
                                    <li style={{fontSize: "20px", fontWeight: 400}}>550 € (EXTRA HOUR after 7pm additional 100 €)</li>
                                    <li style={{fontSize: "20px", fontWeight: 400}}>With an instructor, deposit needed (1500€)</li>
                                    <li style={{fontSize: "20px", fontWeight: 400}}><b>What’s included:</b> Lift board, charger and protection equipment
                                    </li>
                                    <li style={{fontSize: "20px", fontWeight: 400}}><b>Requirements:</b> Passport or ID card
                                    </li>
                                    <li style={{fontSize: "20px", fontWeight: 400}}><b>Deliveries:</b>
                                        We can deliver toys on-board anywhere in Boka bay. Other location deliveries are not included in the price.
                                    </li>
                                    <li style={{fontSize: "20px", fontWeight: 400}}><b>What’s included:</b> From 10 am till 7 pm</li>
                                </ul>
                                <EFoilButton color="black" onClick={() => goToContact()}/>
                            </Group>
                        </Center>
                    </Group>
                </Grid.Col>
            </Grid>
            <Footer/>
        </>
    );
};

export default MNESchool;
