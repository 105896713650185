import React, { forwardRef, FunctionComponent, useState } from 'react';
import ContactBG from "../resources/contact_bg.jpg";
import { Avatar, Center, Grid, Group, Select, Stack, TextInput, Title, Text } from "@mantine/core";
import { useForm, useMediaQuery } from "@mantine/hooks";
import EFoilButton from "../components/EFoilButton";
import Footer from "../Footer";
import { DatePicker } from '@mantine/dates';
import format from 'date-fns/format';
import SRBFlag from "../resources/serb_flag.png";
import MNEFlag from "../resources/mne_flag.png";

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
    image: string;
    label: string;
    description: string;
}

const Contact: FunctionComponent<any> = (props) => {
    const [inquiryDate, setInquiryDate] = useState<Date | null>(null);

    const isSmallScreen = useMediaQuery('(max-width: 500px)');

    const contactForm = useForm({
        initialValues: {
            fullName: '',
            phone: '',
            email: '',
            countrySelect: 'Serbia'
        },

        validationRules: {
            email: (value) => (/^\S+@\S+$/.test(value))
        },
    });

    const countrySelectData = [
        {
            image: SRBFlag,
            label: 'Serbia',
            value: 'Serbia'
        },

        {
            image: MNEFlag,
            label: 'Montenegro',
            value: 'Montenegro'
        },
    ];

    const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
        ({ image, label, ...others }: ItemProps, ref) => (
            <div ref={ref} {...others}>
                <Group noWrap>
                    <Avatar src={image} />

                    <div>
                        <Text size="sm">{label}</Text>
                    </div>
                </Group>
            </div>
        )
    );

    const dateString = format(new Date(inquiryDate!), 'dd-MM-yy');
    const emailBody = `Hi, I would like to make a booking for the ${dateString} in ${contactForm.values.countrySelect}. My name is ${contactForm.values.fullName}, you can contact me on ${contactForm.values.phone}`

    return (
        <>
            <div
                style={{
                    position: "relative",
                    width: "100%",
                    height: "100%",
                    background: "linear-gradient( rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25) ), url(" + ContactBG + ")",
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat'
                }}
            >
                <Center style={{height: "100%"}}>
                    <Stack style={{maxWidth: isSmallScreen ? "340px" : "400px"}} mt={isSmallScreen ? 50 : 0}>
                        <Title style={{color: "white", fontSize: "44px", textAlign: "center"}}>CONTACT</Title>
                        <Grid>
                            <Grid.Col span={6}>
                                <DatePicker
                                    styles={() => ({
                                        root: {
                                            height: 50
                                        },
                                        wrapper: {
                                            height: 50
                                        },
                                        input: {
                                            height: 50,
                                            fontSize: 18
                                        }
                                    })}
                                    placeholder="Date of inquiry"
                                    dropdownType="modal"
                                    value={inquiryDate}
                                    onChange={(val) => setInquiryDate(val!)}
                                />
                            </Grid.Col>
                            <Grid.Col span={6}>
                                <Select
                                    styles={() => ({
                                        root: {
                                            height: 50
                                        },
                                        wrapper: {
                                            height: 50
                                        },
                                        input: {
                                            height: 50,
                                            fontSize: 18
                                        }
                                    })}
                                    itemComponent={SelectItem}
                                    {...contactForm.getInputProps('countrySelect')}
                                    data={countrySelectData}
                                />
                            </Grid.Col>
                            <Grid.Col span={12}>
                                <TextInput
                                    placeholder="Your full name..."
                                    size="lg"
                                    {...contactForm.getInputProps('fullName')}
                                />
                            </Grid.Col>
                            <Grid.Col span={isSmallScreen ? 12 : 6}>
                                <TextInput
                                    type="tel"
                                    placeholder="Your number..."
                                    size="lg"
                                    {...contactForm.getInputProps('phone')}
                                />
                            </Grid.Col>
                            <Grid.Col span={isSmallScreen ? 12 : 6}>
                                <TextInput
                                    type="email"
                                    placeholder="Your email..."
                                    size="lg"
                                    {...contactForm.getInputProps('email')}
                                />
                            </Grid.Col>
                        </Grid>
                        <Center style={{marginTop: "10px"}}>
                            <a href={`mailto:fly.efoil.school@gmail.com?subject=E-Foil Inquiry&body=${emailBody}`}>
                                <EFoilButton/>
                            </a>
                        </Center>
                    </Stack>
                </Center>
            </div>
            <Footer/>
        </>
    );
};

export default Contact;
