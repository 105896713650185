import React, { FunctionComponent } from 'react';
import { Center, Grid, Group, Image, Space, Text, Title } from "@mantine/core";
import TeamBuildings from "../resources/team_buildings.jpg";
import Provide1 from "../resources/provide_1.png";
import Provide2 from "../resources/provide_2.png";
import Provide3 from "../resources/provide_3.png";
import Provide4 from "../resources/provide_4.jpg";
import Provide5 from "../resources/provide_5.png";
import { useMediaQuery } from "@mantine/hooks";
import EFoilButton from "../components/EFoilButton";
import Footer from "../Footer";
import { useNavigate } from "react-router-dom";

const TeamBuilding: FunctionComponent<any> = (props) => {
    let navigate = useNavigate();

    const isSmallScreen = useMediaQuery('(max-width: 500px)');

    const goToContact = () => {
        navigate("Contact", {replace: true})
    }

    return (
        <>
            <div
                style={{
                    position: "relative",
                    width: "100%",
                    height: "100%",
                    background: "linear-gradient( rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25) ), url(" + TeamBuildings + ")",
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat'
                }}
            >
                <Center style={{height: "100%"}}>
                    <Title style={{color: "white", fontSize: isSmallScreen ? "64px" : "100px", fontWeight: 700, textAlign: "center"}}>TEAM BUILDING</Title>
                </Center>
            </div>
            <Grid>
                <Grid.Col span={isSmallScreen ? 12 : 6} p={isSmallScreen ? 50 : 80} pt={isSmallScreen ? 40 : 45} pb={isSmallScreen ? 0 : "unset"} style={{backgroundColor: "#C2C2C2"}}>
                    <Title
                        style={{
                            fontStyle: "normal",
                            fontWeight: 700,
                            fontSize: isSmallScreen ? 30 : 80,
                            lineHeight: isSmallScreen ? "40px" : "80px"
                        }}
                    >
                        LOOKING TO DO
                        SOMETHING FUN
                        WITH YOUR
                        CO-WORKERS?
                    </Title>
                </Grid.Col>
                <Grid.Col span={isSmallScreen ? 12 : 6} pt={isSmallScreen ? 20 : 50} p={isSmallScreen ? 50 : 80} style={{backgroundColor: "#C4C4C4"}}>
                    <Text style={{
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: isSmallScreen ? 24 : 28,
                        lineHeight: "33px"
                    }}>
                        <p>We offer half-day trips that are perfect for team building and corporate events.</p>
                        <br/>
                        <p>
                            Our instructor’s goal is to get you E-FOILING on your first experience.<br/>
                            Unlike kiteboarding, snowboarding, or skiing, the learning curve is far easier and with a greater reward.
                            Even for those with no previous water sports experience with focus on safety whilst building your confidence and skills we can get you FOILING.
                        </p>
                        <br/>
                        <p>The freedom to ride anywhere, anytime, without wind or waves. It is fast, quest and emission free!</p>
                        <br/>
                        <p>Escape the crowds - fly anywhere, anytime, alone with your instructor or ride with friends and share the learning experience.</p>
                    </Text>
                </Grid.Col>
                <Grid.Col span={12} p={0} style={{minHeight: isSmallScreen ? "unset" : "620px"}}>
                    <Group
                        grow
                        p={0}
                        spacing={0}
                        direction={isSmallScreen ? "column" : "row"}
                        style={{height: isSmallScreen ? "unset" : "100%"}}
                        position="left"
                    >
                        <Group
                            pl={isSmallScreen ? 55 : 110}
                            pr={isSmallScreen ? 55 : 110}
                            pb={30}
                            pt={isSmallScreen ? 30 : "unset"}
                            grow
                            spacing={0}
                            position="center"
                            direction="column"
                            align="flex-start"
                        >
                            <Title mb={20} style={{fontWeight: 700, fontSize: isSmallScreen ? 30 : 40}}>WE PROVIDE</Title>
                            <Group position="left" direction="row" style={{minWidth: isSmallScreen ? "100%" : 540, maxHeight: 380}}>
                                <Image src={Provide2} style={{width: "24%", height: "auto"}}/>
                                <Image src={Provide4} style={{width: "24%", height: "auto"}}/>
                                <Image src={Provide1} style={{width: "40%", height: "auto"}}/>
                                <Image src={Provide5} style={{width: "24%", height: "auto"}}/>
                                <Image src={Provide3} style={{width: "24%", height: "auto"}}/>
                            </Group>
                        </Group>
                        <Group
                            pl={isSmallScreen ? 55 : 110}
                            pr={isSmallScreen ? 55 : 110}
                            pb={30}
                            pt={isSmallScreen ? 30 : "unset"}
                            grow
                            spacing={0}
                            position="center"
                            direction="column"
                            align="flex-start"
                        >
                            <Title mb={20} style={{fontWeight: 700, fontSize: isSmallScreen ? 30 : 40}}>YOU NEED</Title>
                            <Text style={{fontWeight: 400, fontSize: isSmallScreen ? 18 : 24}}>
                                <ul style={{marginLeft: isSmallScreen ? 20 : 22}}>
                                    <li>Towels and toiletries</li>
                                    <li>Sunscreen</li>
                                    <li>Appropriate swimwear</li>
                                    <li>A rash guard or other swim shirt</li>
                                    <li>Clothes for before and after</li>
                                </ul>
                            </Text>
                            <Space h="lg"/>
                            <Text mb={30} style={{fontWeight: 500, fontSize: isSmallScreen ? 20 : 24}}>
                                Please arrive at a minimum 15 minutes before your guided tour
                            </Text>
                            <EFoilButton color="black" onClick={() => goToContact()}/>
                        </Group>
                    </Group>
                </Grid.Col>
            </Grid>
            <Footer/>
        </>
    );
};

export default TeamBuilding;
