import React, { FunctionComponent } from 'react';
import { Grid, Text, Image, Anchor } from "@mantine/core";
import Shuriken from "./resources/Shuriken.svg";
import { useMediaQuery } from "@mantine/hooks";

const Footer: FunctionComponent<any> = (props) => {

    const isSmallScreen = useMediaQuery('(max-width: 500px)');

    return (
        <Grid
            m={0}
            grow
            p={60}
            justify="space-between"
            align="center"
            style={{background: "black"}}
        >
            <Grid.Col
                style={{
                    textAlign: "center",
                    maxWidth: isSmallScreen ? "unset" : "max-content",
                    display: "flex",
                    flexDirection: "column"
                }}
            >
                <Anchor
                    href="mailto:info@e-foil.me"
                    style={{
                        width: isSmallScreen ? "unset" : "max-content",
                        fontSize: 28,
                        color: "white",
                        textDecoration: "none"
                    }}
                    target="_blank"
                >
                    EMAIL
                </Anchor>
                <Anchor
                    href="https://www.instagram.com/fly.efoil/"
                    style={{
                        width: isSmallScreen ? "unset" : "max-content",
                        fontSize: 28,
                        color: "white",
                        textDecoration: "none"
                    }}
                    target="_blank"
                >
                    INSTAGRAM
                </Anchor>
                <Anchor
                    href="https://mantine.dev/"
                    style={{
                        width: isSmallScreen ? "unset" : "max-content",
                        fontSize: 28,
                        color: "white",
                        textDecoration: "none"
                    }}
                    target="_blank"
                >
                    TIKTOK
                </Anchor>
            </Grid.Col>
            <Grid.Col style={{textAlign: "center", maxWidth: isSmallScreen ? "unset" : "max-content"}}>
                <Image src={Shuriken} width={isSmallScreen ? 150 : "unset"} height={isSmallScreen ? 150 : "unset"}

                       styles={(theme) => ({
                           image: {
                               marginLeft: "auto",
                               marginRight: "auto"
                           }
                       })}
                />
            </Grid.Col>
            <Grid.Col style={{textAlign: "center", maxWidth: isSmallScreen ? "unset" : "max-content"}}>
                <Text
                    color="white"
                    style={{
                        width: isSmallScreen ? "unset" : "max-content", fontSize: 30, fontWeight: "bold"
                    }}
                >
                    CONTACT
                </Text>
                <Text
                    color="white"
                    style={{
                        width: isSmallScreen ? "unset" : "max-content", fontSize: 28
                    }}
                >
                    +381665056377
                </Text>
                <Text
                    color="white"
                    style={{
                        width: isSmallScreen ? "unset" : "max-content", fontSize: 28
                    }}
                >
                    +381655660012
                </Text>
            </Grid.Col>
        </Grid>
    );
};

export default Footer;
