import React, { FunctionComponent } from 'react';
import HomePage1 from "../resources/home_page_1.jpg";
import HomePage2 from "../resources/lift_1.jpg";
import HomePage3 from "../resources/lift_2.jpg";
import DemoShows from "../resources/demo_shows.jpg";
import TeamBuildings from "../resources/team_buildings.jpg";
import WhatIsEFoil from "../resources/WHAT-IS-E-FOIL.svg";
import LiftLogo from "../resources/lift_logo.svg";
import { Center, Grid, Group, Image, Text, Title } from '@mantine/core';
import { useMediaQuery } from "@mantine/hooks";
import EFoilButton from "../components/EFoilButton";
import Footer from "../Footer";
import { useNavigate } from "react-router-dom";

const HomePage: FunctionComponent<any> = (props) => {
    let navigate = useNavigate();

    const isSmallScreen = useMediaQuery('(max-width: 500px)');

    const goToDemoShows = () => {
        navigate("Demo", {replace: true})
    }

    const goToTeamBuilding = () => {
        navigate("TeamBuilding", {replace: true})
    }

    const goToContact = () => {
        navigate("Contact", {replace: true})
    }

    const goToSchool = () => {
        navigate("Schools", {replace: true})
    }

    return (
        <>
            <div
                style={{
                    position: "relative",
                    width: "100%",
                    height: "100%",
                    backgroundImage: "url(" + HomePage1 + ")",
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat'
                }}
            >
                <Center style={{height: "100%"}}>
                    <Image
                        style={{
                            width: isSmallScreen ? "60%" : "40%",
                            height: "auto",
                            objectFit: "cover"
                        }}
                        src={WhatIsEFoil}
                    />
                </Center>
            </div>
            <Grid style={{minHeight: isSmallScreen ? "unset" : "620px"}}>
                <Grid.Col span={isSmallScreen ? 12 : 6} p={isSmallScreen ? 50 : 80} pt={isSmallScreen ? 20 : 50} pb={isSmallScreen ? 0 : "unset"}>
                    <Group position="left" direction="column">
                        {
                            isSmallScreen ? (
                                <>
                                    <Image src={LiftLogo} width={200} height="auto" style={{marginLeft: "auto", marginRight: "auto"}}/>
                                    <Title
                                        style={{
                                            fontStyle: "normal",
                                            fontWeight: 700,
                                            fontSize: 30,
                                            lineHeight: "40px",
                                            textTransform: "uppercase"
                                        }}
                                    >
                                        LEARN TO FLY WITH US!
                                    </Title>
                                </>
                            ) : (
                                <>
                                    <Title
                                        style={{
                                            fontStyle: "normal",
                                            fontWeight: 700,
                                            fontSize: 80,
                                            lineHeight: "80px",
                                            textTransform: "uppercase"
                                        }}
                                    >
                                        LEARN TO FLY WITH US!
                                    </Title>
                                    <Image src={LiftLogo} width={330} mt={80} height="auto" style={{marginLeft: "unset", marginRight: "unset"}}/>
                                </>
                            )
                        }
                    </Group>
                </Grid.Col>
                <Grid.Col span={isSmallScreen ? 12 : 6} pt={isSmallScreen ? 20 : 60} p={isSmallScreen ? 50 : 80}>
                    <Text style={{
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: isSmallScreen ? 22 : 30,
                        lineHeight: "33px"
                    }}>
                        Learn to fly with the new E-Foil by Lift foils in Montenegro and Serbia.
                        Our experts will teach you how to get on the board and surf the crystal clear waters of our amazing coastline.
                        For a fixed price, you will enjoy a class taught by a specialist and live an unforgettable experience uploaded to a Lift board, the new flying surfboard.
                    </Text>
                    <br/>
                    <Text style={{
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: isSmallScreen ? 22 : 30,
                        lineHeight: "33px"
                    }}>
                        We are seeking to craft the best experience for you!
                        Reach out to us and let’s personalise your request. We offer a variety of boat experiences, private lessons in different locations or demo for interested buyers.
                    </Text>
                </Grid.Col>
            </Grid>
            <Grid style={{minHeight: "600px"}}>
                <Grid.Col
                    style={{
                        objectFit: "cover",
                        position: "relative",
                        background: "linear-gradient( rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25) ), url(" + HomePage2 + ")",
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        textAlign: 'center'
                    }}
                    span={isSmallScreen ? 12 : 6}
                    p={100}
                >
                    <Center style={{display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%'}}>
                        <Text
                            style={{
                                fontSize: isSmallScreen ? 48 : 80,
                                fontWeight: 'bold'
                            }}
                            color="white"
                        >
                            SCHOOL
                        </Text>
                        <EFoilButton onClick={() => goToSchool()}/>
                    </Center>
                </Grid.Col>
                <Grid.Col
                    style={{
                        objectFit: "cover",
                        position: "relative",
                        background: "linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(" + HomePage3 + ")",
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        textAlign: 'center'
                    }}
                    span={isSmallScreen ? 12 : 6}
                    p={100}
                >
                    <Center style={{display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%'}}>
                        <Text
                            style={{
                                fontSize: isSmallScreen ? 48 : 80,
                                fontWeight: 'bold'
                            }}
                            color="white"
                        >
                            TOURS
                        </Text>
                        <EFoilButton onClick={() => goToContact()}/>
                    </Center>
                </Grid.Col>
            </Grid>
            <Grid style={{minHeight: "750px"}}>
                <Grid.Col
                    style={{
                        objectFit: "cover",
                        position: "relative",
                        background: "linear-gradient( rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25) ), url(" + DemoShows + ")",
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        textAlign: 'center',
                        display: "flex",
                        flexDirection: 'row',
                        alignItems: 'flex-end'
                    }}
                    span={12}
                    p={isSmallScreen ? 50 : 100}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: isSmallScreen ? 'center' : 'flex-start'
                        }}
                    >
                        <Text
                            style={{
                                fontSize: isSmallScreen ? 42 : 80,
                                fontWeight: 'bold',
                                width: "max-content"
                            }}
                            color="white"
                        >
                            DEMO SHOWS
                        </Text>
                        <Text
                            style={{
                                fontStyle: "normal",
                                fontWeight: 500,
                                fontSize: isSmallScreen ? 26 : 30,
                                lineHeight: "33px",
                                maxWidth: isSmallScreen ? "100%" : "50%",
                                textAlign: isSmallScreen ? "center" : "left",
                                color: "white"
                            }}
                            mb={30}
                        >
                            Try before you buy trials for potential buyers, promotional rides for different events, and school & rental collaborations
                        </Text>
                        <EFoilButton onClick={() => goToDemoShows()}/>
                    </div>
                </Grid.Col>
            </Grid>
            <Grid style={{minHeight: "750px"}}>
                <Grid.Col
                    style={{
                        objectFit: "cover",
                        position: "relative",
                        background: "linear-gradient( rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25) ), url(" + TeamBuildings + ")",
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        textAlign: 'center',
                        display: "flex",
                        flexDirection: 'column',
                        alignItems: 'flex-end'
                    }}
                    span={12}
                    p={isSmallScreen ? 20 : 100}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: isSmallScreen ? "center" : 'flex-end',
                            alignSelf: "flex-end",
                            marginTop: "auto"
                        }}
                    >
                        <Text
                            style={{
                                fontSize: isSmallScreen ? 42 : 80,
                                fontWeight: 'bold',
                                width: "max-content",
                                whiteSpace: "nowrap"
                            }}
                            color="white"
                        >
                            TEAM BUILDINGS
                        </Text>
                        <Text
                            style={{
                                fontStyle: "normal",
                                fontWeight: 500,
                                fontSize: isSmallScreen ? 26 : 30,
                                lineHeight: "33px",
                                maxWidth: "100%",
                                textAlign: isSmallScreen ? "center" : "left",
                                color: "white"
                            }}
                            mb={30}
                        >
                            Looking to do something fun with your co-workers? <br/>
                            We offer day trips with our professional staff
                        </Text>
                        <EFoilButton onClick={() => goToTeamBuilding()}/>
                    </div>
                </Grid.Col>
            </Grid>
            <Footer/>
        </>
    );
};

export default HomePage;
