import React, { FunctionComponent } from 'react';
import { Image } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import BlackEFoilButton from "../resources/book_now_black.gif"
import WhiteEFoilButton from "../resources/book_now_white.gif"

interface EFoilButtonProps {
    onClick?: () => void,
    color?: "black" | "white"
}

const EFoilButton: FunctionComponent<EFoilButtonProps> = (props) => {
    const isSmallScreen = useMediaQuery('(max-width: 500px)');

    const {onClick, color} = props;

    return (
        <Image
            src={color === "black" ? BlackEFoilButton : WhiteEFoilButton}
            width={isSmallScreen ? 200 : 250}
            style={{
                cursor: 'pointer',
                marginLeft: isSmallScreen ? "auto" : "unset",
                marginRight: isSmallScreen ? "auto" : "unset"
            }}
            onClick={onClick}
        />
        // <UnstyledButton
        //     style={{
        //         border: color ? `3px solid ${color}` : "3px solid #FFFFFF",
        //         color: color ? color : "white",
        //         width: "max-content"
        //     }}
        //     p={20}
        //     pt={10}
        //     pb={10}
        //     onClick={onClick}
        // >
        //     <Text
        //         style={{
        //             fontSize: isSmallScreen ? 16 : 24
        //         }}
        //     >
        //         {text}
        //     </Text>
        // </UnstyledButton>
    );
};

export default EFoilButton;
